<template>
    <div class="container-fluid">
      <page-title :title="`Add Bank`" />
  
      <div class="row justify-content-cente">
        <div class="col-md-8">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="save">
                <div class="">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Bank Name</label>
                        <input
                          required
                          type="text"
                          class="form-control"
                          v-model="bank.name"
                          placeholder="Bank Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Email Address</label>
                        <input
                          required
                          type="email"
                          class="form-control"
                          v-model="bank.email_address"
                          placeholder="e.g  abc@gmail.com"
                        />
                      </div>
                    </div>
                 
                    <div class="col-md-12">
                      <div class="form-group">
                        <button class="btn btn-secondary float-right">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        bank: {
            name: "",
            email_address: "",
        },
      };
    },
  
    methods: {

      save() {
        this.$loader.start()
  
  
        var myData = new FormData();
        myData.append("name", this.bank.name);
        myData.append("email_address", this.bank.email_address);
    
  
        this.$axios
          .post("/api/v1/dashboard/banks", myData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then((response) => {
            this.$loader.stop()
            this.$router.push({
              name: "banks.show",
              params: { id: response.data.unique_id },
            });
          })
          .catch((error) => {
            this.$alert.httpError(error.response);
          });
      },
    },
  };
  </script>
  